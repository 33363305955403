import "../index.css";

export default function Credits() {
  return (
    <div className="credits-wrapper">
      Special thanks to :
      <ul>
        <li>
          Photo by <a href="https://unsplash.com/@nate_dumlao?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Nathan Dumlao</a> on <a href="https://unsplash.com/photos/person-holding-babys-feet-EytWx3BOrwI?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
        </li>

        <li>
          Photo by <a href="https://unsplash.com/@krakenimages?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">krakenimages</a> on <a href="https://unsplash.com/photos/girl-in-blue-denim-dungaree-pants-holding-blue-and-white-polka-dot-handbag-Br-ayoAxFuQ?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
        </li>

        <li>
          Photo by <a href="https://unsplash.com/@taypaigey?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Taylor Flowe</a> on <a href="https://unsplash.com/photos/boy-in-black-hoodie-sitting-on-chair-4nKOEAQaTgA?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
        </li>

        <li>
          Photo by <a href="https://unsplash.com/@bewakoofofficial?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Bewakoof.com Official</a> on <a href="https://unsplash.com/photos/woman-sitting-on-swing-mG-HdjYiPtE?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
        </li>

        <li>
          Photo by <a href="https://unsplash.com/@jasongoodman_youxventures?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Jason Goodman</a> on <a href="https://unsplash.com/photos/woman-placing-sticky-notes-on-wall-Oalh2MojUuk?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
        </li>

        <li>
          Photo by <a href="https://unsplash.com/@vicfurtuna?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Victor Furtuna</a> on <a href="https://unsplash.com/photos/man-and-woman-standing-near-body-of-water-during-daytime-lL01_0T13Lw?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
        </li>

        <li>
          Photo by <a href="https://unsplash.com/@ninanaction?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Nina Hill</a> on <a href="https://unsplash.com/photos/person-in-white-long-sleeve-shirt-and-black-pants-VsbdJGrjmp8?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">Unsplash</a>
        </li>
        
        <li>
          <a href="https://www.flaticon.com/free-icons/teddy-bear" title="teddy-bear icons">Teddy-bear icons created by Freepik - Flaticon</a>
        </li>

        <li>
          Sound Effect from <a href="https://pixabay.com/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=34179">Pixabay</a>
        </li>
      </ul>
    </div>
  );
}
