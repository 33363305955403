import { useFrame } from "@react-three/fiber"
import { useRef } from "react"

export function Rig(props) {
  const ref = useRef()

  const cameraTargetDistance = 60;
  let cameraAnimation = true;

  // Get HTML content using DOM
  const rootWrapper = document.getElementById("r3f-wrapper");
  let counter = 0;

  useFrame((state, delta) => {
    if (props.started){
      counter += 0.0008;
    }

    ref.current.rotation.y = -counter * (Math.PI * 2) // Rotate contents
    state.events.update() // Raycasts every frame rather than on pointer-move

    if (cameraAnimation && props.started){
      state.camera.position.z -= 0.5;
    }

    if (state.camera.position.z <= cameraTargetDistance){
      cameraAnimation = false;
    }

    if (counter > 1){
      counter = 0;
    }

    if (counter < 0.25){
      rootWrapper.classList.add("morning-background");
      rootWrapper.classList.remove("daylight-background", "afternoon-background", "night-background");
    } else if (counter < 0.5){
      rootWrapper.classList.add("daylight-background");
      rootWrapper.classList.remove("morning-background", "afternoon-background", "night-background");
    } else if (counter < 0.75){
      rootWrapper.classList.add("afternoon-background");
      rootWrapper.classList.remove("daylight-background", "morning-background", "night-background");
    } else if (counter < 1){
      rootWrapper.classList.add("night-background");
      rootWrapper.classList.remove("daylight-background", "afternoon-background", "morning-background");
    }
  })
  
  return <group ref={ref} {...props} />
}