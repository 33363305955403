import {
  OrbitControls
} from "@react-three/drei";
import { Suspense } from "react";
import { Book } from "./components/Book";
import { Rig } from "./components/Rig";
import { Banner } from "./components/Banner";
import { Carousel } from "./components/Carousel";
import { Sky } from "./components/Sky";

export function Scene({ started }){
  return (
    <Suspense fallback={null}>
      <directionalLight 
        color="white" 
        position={[5, 10, 20]} 
        intensity={2.5} 
        />

      <hemisphereLight color="white" intensity={2} />

      <OrbitControls
        minDistance={10}
        maxDistance={100}/>

      <Sky />
      
      <Book />

      <Rig started={started} rotation={[0, 0, 0.15]}>
        <Carousel />
      </Rig>
      <Banner position={[0, -3, 0]} />
    </Suspense>
  );
}