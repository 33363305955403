import { useFrame } from "@react-three/fiber"
import { useRef, useState } from "react"
import { easing } from 'maath'
import { Image } from "@react-three/drei"
import { DoubleSide } from "three"
import "./../systems/util"

export function Card({ url, ...props }) {
  const ref = useRef()
  const [hovered, hover] = useState(false)
  const pointerOver = (e) => (e.stopPropagation(), hover(true))
  const pointerOut = () => hover(false)

  useFrame((state, delta) => {
    easing.damp3(ref.current.scale, hovered ? 1.15 : 1, 0.1, delta)
    easing.damp(ref.current.material, 'radius', hovered ? 0.25 : 0.15, 0.2, delta)
    easing.damp(ref.current.material, 'zoom', hovered ? 1 : 1.5, 0.2, delta)
  })

  return (
    <Image ref={ref} url={url} transparent side={DoubleSide} onPointerOver={pointerOver} onPointerOut={pointerOut} {...props}>
      <bentPlaneGeometry args={[0.1, 3, 3, 20, 20]} />
    </Image>
  )
}