import { useProgress } from "@react-three/drei";

export const LoadingScreen = ({ started, onStarted }) => {
  const { progress } = useProgress();

  return (
    <div className={`loadingScreen ${started ? "loadingScreen--started" : ""}`}>
      <div className="clock-wrapper">
        <div className="clock"></div>
      </div>
      
      {progress < 100 && 
        <div className="loadingLabel">Karya sedang disiapkan... { Math.floor(progress) }%</div>
      }
      {progress === 100 && 
        <button
          onClick={onStarted}
          className="loadingButton"
          id="btn-start-website">
          Klik di sini untuk melihat karya
        </button>
      }
    </div>
  );
};
