import "../index.css";
import { Canvas } from "@react-three/fiber";
import { Scene } from "../Scene";
import { StatsGl } from "@react-three/drei";
import { useEffect, useState } from "react";
import { LoadingScreen } from "../components/LoadingScreen";

const entranceAudio = new Audio("./audios/entrance.mp3");
const ambienceAudio = new Audio("./audios/music-box-34179.mp3");

export default function Home() {

  const [start, setStart] = useState(false);

  useEffect(() => {
    if (start) {
      entranceAudio.onended = () => {
        ambienceAudio.loop = true;
        ambienceAudio.play();
      };
      entranceAudio.play();
    }
  }, [start]);

  return (
    <div id="r3f-wrapper">
      <div id="content-information" className="content hidden">
        Tulisan pertamaku di buku antologi berjudul <b>"ADULTING"</b>.<br/><br/>
        Semakin beranjak dewasa rasanya semakin penting untuk mengetahui kebahagiaan versi diri sendiri. Tidak harus selalu sama dengan yang lain, tidak harus selalu menjadi yang orang lain harapkan, cukup menjadi diri sendiri dan ciptakan kebahagiaan.<br/><br/>
        Selamat bertumbuh semuanya!<br/><br/>
        — Shanty F. Putri
      </div>

      <div className="brand-information">Powered by <a href="https://develobe.id/" target="_blank" rel="noreferrer" className="action-link" id="btn-develobe-brand">DEVELOBE.ID</a> | <a href="/credits" target="_blank" className="action-link" id="btn-credits">Assets Credits</a></div>

      <Canvas camera={{ position: [0, 0, 100], fov: 15 }}>
        {/* <StatsGl />
        <axesHelper args={[10]}/> */}
        <Scene started={start}/>
      </Canvas> 

      <LoadingScreen started={start} onStarted={() => setStart(true)} />
    </div>
  );
}
