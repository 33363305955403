import { useScroll, useTexture } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"
import { useRef } from "react"
import { DoubleSide, RepeatWrapping } from "three"
import "./../systems/util"

export function Banner(props) {
  const ref = useRef()
  const texture = useTexture('/images/work_.png')
  texture.wrapS = texture.wrapT = RepeatWrapping
  const scroll = useScroll()
  
  useFrame((state, delta) => {
    ref.current.material.time.value += Math.abs(0.005) * 4
    ref.current.material.map.offset.x += delta / 5
  })

  return (
    <mesh ref={ref} {...props}>
      <cylinderGeometry args={[6.6, 6.6, 0.56, 128, 16, true]} />
      <meshSineMaterial map={texture} map-anisotropy={16} map-repeat={[6, 1]} side={DoubleSide} toneMapped={false} />
    </mesh>
  )
}