import { useGLTF } from "@react-three/drei";
import { MathUtils } from "three/src/math/MathUtils";

export function Book() {
  // Prepare variable
  const { scene } = useGLTF("/models/book_with_cover.glb");
  const position = [0, 0, 0];
  const rotation = [0, 0, 0];
  const scale = [8, 8, 8];

  // Set position of the book
  position[0] = -0.4 * scale[0];
  position[1] = 0;
  position[2] = 0.3;

  // Rotate the book to face the camera
  rotation[0] = MathUtils.degToRad(90);

  // Get content information using DOM
  const contentInfo = document.getElementById("content-information");

  const pointerOver = (e) => {
    if (contentInfo){
      contentInfo.classList.remove("hidden");
    }
  }

  const pointerOut = () => {
    if (contentInfo){
      contentInfo.classList.add("hidden");
    }
  }

  return (
    <group name="book" onPointerOver={pointerOver} onPointerOut={pointerOut}>
      <primitive object={scene} position={position} rotation={rotation} scale={scale}/>
    </group>
  );
}
