import { Card } from "./Card";

export function Carousel({ radius = 5.6, count = 7 }) {
  return Array.from({ length: count }, (_, i) => (
    <Card
      key={i}
      url={`/images/img${Math.floor(i % 10) + 1}_.jpg`}
      position={[Math.sin((i / count) * Math.PI * 2) * radius, -3, Math.cos((i / count) * Math.PI * 2) * radius]}
      rotation={[0, Math.PI + (i / count) * Math.PI * 2, 0]}
    />
  ))
}